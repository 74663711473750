'use client';

import Image from 'next/image';
import { CallOutlined, ChatOutlined, MailOutlineOutlined, PinDropOutlined } from '@mui/icons-material';
import { Box, Container, Grid, Stack, Typography, Link as MuiLink } from '@mui/material';
import DemoLogo from '../_assets/lakewood-logo.png';
const linkStyles = {
  color: 'tertiary.contrastText',
  textDecoration: 'underline',
  display: 'flex',
  alignItems: 'center'
};
export const Footer = () => {
  const handleStartChatClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window?.salesPilotPostMessages?.openChatPage();
  };
  return <Box sx={({
    palette
  }) => ({
    backgroundImage: {
      // trick to have background colors correct and keep content in Container
      md: `linear-gradient(to right, ${palette.common.white} 50%, ${palette.tertiary.dark} 50%)`
    }
  })} data-sentry-element="Box" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <Container sx={{
      px: {
        xs: 0,
        md: 'unset'
      }
    }} data-sentry-element="Container" data-sentry-source-file="Footer.tsx">
        <Grid container component="footer" data-sentry-element="Grid" data-sentry-source-file="Footer.tsx">
          <Grid item xs={12} md={4} pr={{
          xs: 0,
          sm: 3
        }} py={6} sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }} data-sentry-element="Grid" data-sentry-source-file="Footer.tsx">
            <MuiLink href="/" sx={{
            position: 'relative',
            height: 150,
            width: {
              xs: '90%',
              lg: '100%'
            }
          }} data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
              <Image src={DemoLogo} fill style={{
              objectFit: 'contain'
            }} alt="Demo logo" sizes="350px" data-sentry-element="Image" data-sentry-source-file="Footer.tsx" />
            </MuiLink>
          </Grid>
          <Grid item xs={12} md={8} px={{
          xs: 4,
          lg: 8
        }} pt={6} pb={{
          xs: 10,
          md: 6
        }} sx={{
          bgcolor: 'tertiary.dark'
        }} data-sentry-element="Grid" data-sentry-source-file="Footer.tsx">
            <Stack direction={{
            xs: 'column',
            sm: 'row'
          }} spacing={{
            xs: 5,
            lg: 14
          }} justifyContent={{
            xs: 'space-evenly',
            lg: 'flex-start'
          }} data-sentry-element="Stack" data-sentry-source-file="Footer.tsx">
              <Stack data-sentry-element="Stack" data-sentry-source-file="Footer.tsx">
                <Typography variant="h5" component="h3" color="tertiary.contrastText" mb={4} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                  Quick Links
                </Typography>
                <FooterLink href="/about" data-sentry-element="FooterLink" data-sentry-source-file="Footer.tsx">About us</FooterLink>
                <FooterLink href="/our-services" data-sentry-element="FooterLink" data-sentry-source-file="Footer.tsx">Our services</FooterLink>
                <FooterLink href="/contact" data-sentry-element="FooterLink" data-sentry-source-file="Footer.tsx">Contact</FooterLink>
                <FooterLink href="/careers" data-sentry-element="FooterLink" data-sentry-source-file="Footer.tsx">Careers</FooterLink>
              </Stack>
              <Stack data-sentry-element="Stack" data-sentry-source-file="Footer.tsx">
                <Typography variant="h5" component="h3" color="tertiary.contrastText" mb={4} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                  Contact
                </Typography>
                <MuiLink href="#" onClick={handleStartChatClick} sx={linkStyles} mb={1} data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <ChatOutlined sx={{
                  mr: 2
                }} data-sentry-element="ChatOutlined" data-sentry-source-file="Footer.tsx" />
                  Start the chat
                </MuiLink>
                <MuiLink href="mailto:example@yourcompany.net" sx={linkStyles} mb={1} data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <MailOutlineOutlined sx={{
                  mr: 2
                }} data-sentry-element="MailOutlineOutlined" data-sentry-source-file="Footer.tsx" />
                  example@yourcompany.net
                </MuiLink>
                <MuiLink href="tel:000-000-0000" sx={linkStyles} mb={1} data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <CallOutlined sx={{
                  mr: 2
                }} data-sentry-element="CallOutlined" data-sentry-source-file="Footer.tsx" />
                  Call: 000-000-0000
                </MuiLink>
                <MuiLink href="https://maps.app.goo.gl/XuW7z67cKE8Yq8uf8" target="_blank" rel="noopener noreferrer" sx={linkStyles} mb={1} data-sentry-element="MuiLink" data-sentry-source-file="Footer.tsx">
                  <PinDropOutlined sx={{
                  mr: 2
                }} data-sentry-element="PinDropOutlined" data-sentry-source-file="Footer.tsx" />
                  Your company address, USA
                </MuiLink>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>;
};
type FooterLinkProps = {
  href: string;
  children: React.ReactNode;
};
const FooterLink = ({
  href,
  children
}: FooterLinkProps) => {
  return <MuiLink href={href} color="tertiary.contrastText" data-sentry-element="MuiLink" data-sentry-component="FooterLink" data-sentry-source-file="Footer.tsx">
      <Typography variant="bodySmall" color="inherit" mb={2} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
        {children}
      </Typography>
    </MuiLink>;
};